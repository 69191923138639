import { Box } from '@mui/material'
import React from 'react'

interface Props {
  height: number
  px: number
  children: React.ReactNode
}

export const SliderWrapper: React.FC<Props> = ({ children, px, height }) => {
  return (
    <Box position='relative' height={height}>
      <Box position='absolute' px={px} left={0} right={0} top='50%' style={{ transform: 'translate(0,-50%)' }}>
        {children}
      </Box>
    </Box>
  )
}
