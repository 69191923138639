import React, { useState } from 'react'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'

interface Column {
  id: string
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'NickName', label: 'NickName', minWidth: 170 },
  { id: 'Games', label: 'Games', minWidth: 100 },
  {
    id: 'Status',
    label: 'Status',
    minWidth: 170,
  },
  {
    id: 'Language',
    label: 'Average playtime',
    minWidth: 170,
  },
]

const rows: any[] = [
  { NickName: 'Prabodhan Fitzgerald', Games: '#2581920', Status: 'Player', Language: 'Language' },
  { NickName: 'Fitzgerald', Games: '#2581920', Status: 'Blogger', Language: 'Spectator' },
  { NickName: 'Thumbiko', Games: '#2581920', Status: 'Player', Language: 'Spectator' },
  { NickName: 'Prabodhan', Games: '#2581920', Status: 'Player', Language: 'Spectator' },
  { NickName: 'Thumbiko James', Games: '#2581920', Status: 'Blogger', Language: 'Streamer' },

  { NickName: 'Prabodhan Fitzgerald', Games: '#2581920', Status: 'Blogger', Language: 'Spectator' },
  { NickName: 'Fitzgerald', Games: '#2581920', Status: 'Blogger', Language: 'Spectator' },
  { NickName: 'Thumbiko', Games: '#2581920', Status: 'Blogger', Language: 'Spectator' },
  { NickName: 'Prabodhan', Games: '#2581920', Status: 'Blogger', Language: 'Spectator' },
  { NickName: 'Thumbiko James', Games: '#2581920', Status: 'Blogger', Language: 'Streamer' },

  { NickName: 'Prabodhan Fitzgerald', Games: '#2581920', Status: 'Player', Language: 'Spectator' },
  { NickName: 'Fitzgerald', Games: '#2581920', Status: 'Player', Language: 'Spectator' },
  { NickName: 'Thumbiko', Games: '#2581920', Status: 'Player', Language: 'Spectator' },
  { NickName: 'Prabodhan', Games: '#2581920', Status: 'Player', Language: 'Spectator' },
  { NickName: 'Thumbiko James', Games: '#2581920', Status: 'Player', Language: 'Streamer' },
]

export const Users = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event: unknown, newPage: number) => setPage(newPage)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <>
      <Box display='flex' justifyContent='space-between' my={4}>
        <Typography variant={'h4'}>Players</Typography>
      </Box>
      <Box>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 'calc(100vh - 250px)' }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <TableRow hover role='checkbox' tabIndex={-1} key={row.NickName}>
                      {columns.map((column) => {
                        const value = row[column.id]
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  )
}
