import React, { useLayoutEffect } from 'react'
import './App.css'
import { Route, Routes } from 'react-router-dom'
import { Home } from './pages/Home'
import { Login } from './pages/Login'
import { Register } from './pages/Register'
import { ROUTES } from './constants/routes'
import { DefaultLayout } from './containers/DefaultLayout'
import { ProtectedLayout } from './containers/ProtectedLayout'
import { Bots } from './pages/Bots/Bots'
import { BotsNew } from './pages/Bots/BotsNew'
import { Games } from './pages/Games'
import { Users } from './pages/Users'
import { Dashboard } from './pages/Dashboard'
import { useAppDispatch, useAppSelector } from './hooks/useReduxHooks'
import { checkToken, ReturnType } from './utils/checkToken'
import { setAuth } from './store/auth/auth.slice'
import { getMe } from './api/auth'
import { Player } from './pages/Users/Player'
import { BotsEdit } from './pages/Bots/BotsEdit'

function App() {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.auth.user)

  useLayoutEffect(() => {
    ;(() => {
      if (!user) {
        checkToken()
          .then((userToken: ReturnType) => {
            if (userToken.success) getMe().then((res) => dispatch(setAuth({ user: res })))
          })
          .catch(() => dispatch(setAuth({ user: undefined })))
      }
    })()
  }, [])

  if (user === null) return <></>

  return (
    <>
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route path={ROUTES.login} element={<Login />} />
          <Route path={ROUTES.register} element={<Register />} />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route path={ROUTES.home} element={<Home />} />
          <Route path={ROUTES.dashboard} element={<Dashboard />} />
          <Route path={ROUTES.bots} element={<Bots />} />
          <Route path={ROUTES.botsNew} element={<BotsNew />} />
          <Route path={ROUTES.botsEdit} element={<BotsEdit />} />
          <Route path={ROUTES.games} element={<Games />} />
          <Route path={ROUTES.users} element={<Users />} />
          <Route path={ROUTES.usersPlayer} element={<Player />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
