import { configureStore, combineReducers } from '@reduxjs/toolkit'
import globalReducer from './global/global.slice'
import authReducer from './auth/auth.slice'
import botsReducer from './bots/bots.slice'

const rootReducer = combineReducers({
  global: globalReducer,
  auth: authReducer,
  bots: botsReducer,
})

export const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
