import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types'

const initialState: IStore['auth'] = {
  user: null,
}

export const defSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state: IStore['auth'], action?) => ({ ...state, ...action?.payload }),
  },
})

export const { setAuth } = defSlice.actions

export default defSlice.reducer
