import React from 'react'

export const AppStoreIcon = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_250_13017)'>
        <path
          d='M23.937 0.00195312H6.05924C2.7109 0.00195312 0 2.71285 0 6.06119V23.9427C0 27.2873 2.7109 29.9982 6.05924 29.9982H23.9408C27.2854 29.9982 30 27.2873 30 23.939V6.06119C29.9963 2.71285 27.2854 0.00195312 23.937 0.00195312Z'
          fill='url(#paint0_linear_250_13017)'
        />
        <path
          d='M14.8697 6.89357L15.4771 5.84369C15.8521 5.1875 16.6882 4.96631 17.3444 5.34125C18.0006 5.71619 18.2218 6.55232 17.8468 7.20852L11.9938 17.3398H16.227C17.5993 17.3398 18.368 18.9521 17.7718 20.0694H5.36092C4.60353 20.0694 3.99609 19.462 3.99609 18.7046C3.99609 17.9472 4.60353 17.3398 5.36092 17.3398H8.84051L13.295 9.61947L11.9039 7.20477C11.529 6.54857 11.7502 5.71994 12.4063 5.3375C13.0625 4.96256 13.8912 5.18375 14.2736 5.83994L14.8697 6.89357Z'
          fill='white'
        />
        <path
          d='M9.60449 21.4755L8.29217 23.7514C7.91723 24.4076 7.08109 24.6288 6.4249 24.2539C5.76871 23.8789 5.54752 23.0428 5.92246 22.3866L6.89734 20.6993C7.99967 20.3581 8.8958 20.6206 9.60449 21.4755Z'
          fill='white'
        />
        <path
          d='M20.9059 17.3472H24.4567C25.2141 17.3472 25.8215 17.9547 25.8215 18.712C25.8215 19.4694 25.2141 20.0769 24.4567 20.0769H22.4844L23.8155 22.3866C24.1904 23.0428 23.9692 23.8714 23.313 24.2538C22.6568 24.6288 21.8282 24.4076 21.4458 23.7514C19.2036 19.8631 17.52 16.9535 16.4027 15.015C15.259 13.0427 16.0765 11.063 16.8826 10.3918C17.7788 11.9292 19.1174 14.2501 20.9059 17.3472Z'
          fill='white'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_250_13017'
          x1='15'
          y1='0.00195313'
          x2='15'
          y2='29.9982'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#00BFFC' />
          <stop offset='1' stopColor='#0073F6' />
        </linearGradient>
        <clipPath id='clip0_250_13017'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
