import React, { useState } from 'react'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'

interface Column {
  id: string
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'Name', label: 'Name', minWidth: 170 },
  { id: 'Download', label: 'Download', minWidth: 100 },
  {
    id: 'Online',
    label: 'Online',
    minWidth: 170,
  },
  {
    id: 'Playtime',
    label: 'Average playtime',
    minWidth: 170,
  },
]

const rows: any[] = [
  { Name: 'Prabodhan Fitzgerald', Download: '#2581920', Online: 12, Playtime: 'Playtime' },
  { Name: 'Fitzgerald', Download: '#2581920', Online: 54, Playtime: 'Spectator' },
  { Name: 'Thumbiko', Download: '#2581920', Online: 43, Playtime: 'Spectator' },
  { Name: 'Prabodhan', Download: '#2581920', Online: 2, Playtime: 'Spectator' },
  { Name: 'Thumbiko James', Download: '#2581920', Online: 4, Playtime: 'Streamer' },

  { Name: 'Prabodhan Fitzgerald', Download: '#2581920', Online: 12, Playtime: 'Spectator' },
  { Name: 'Fitzgerald', Download: '#2581920', Online: 54, Playtime: 'Spectator' },
  { Name: 'Thumbiko', Download: '#2581920', Online: 43, Playtime: 'Spectator' },
  { Name: 'Prabodhan', Download: '#2581920', Online: 2, Playtime: 'Spectator' },
  { Name: 'Thumbiko James', Download: '#2581920', Online: 4, Playtime: 'Streamer' },

  { Name: 'Prabodhan Fitzgerald', Download: '#2581920', Online: 12, Playtime: 'Spectator' },
  { Name: 'Fitzgerald', Download: '#2581920', Online: 54, Playtime: 'Spectator' },
  { Name: 'Thumbiko', Download: '#2581920', Online: 43, Playtime: 'Spectator' },
  { Name: 'Prabodhan', Download: '#2581920', Online: 2, Playtime: 'Spectator' },
  { Name: 'Thumbiko James', Download: '#2581920', Online: 4, Playtime: 'Streamer' },
]

export const Games = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event: unknown, newPage: number) => setPage(newPage)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <>
      <Box display='flex' justifyContent='space-between' my={4}>
        <Typography variant={'h4'}>Games</Typography>
      </Box>
      <Box>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 'calc(100vh - 250px)' }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <TableRow hover role='checkbox' tabIndex={-1} key={row.Download}>
                      {columns.map((column) => {
                        const value = row[column.id]
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  )
}
