import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types'
import { getAllBot } from '../../api/bots'

const initialState: IStore['bots'] = {
  loading: false,
  data: null,
}

export const defSlice = createSlice({
  name: 'bots',
  initialState,
  reducers: {
    setBots: (state: IStore['bots'], action?) => action?.payload,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBots.pending, (state: IStore['bots']) => {
        state.loading = true
        state.errors = null
      })
      .addCase(fetchBots.fulfilled, (state: IStore['bots'], action: any) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(fetchBots.rejected, (state: IStore['bots'], action: any) => {
        state.loading = false
        state.errors = action.errors?.message || 'error'
      })
  },
})

export const fetchBots: any = createAsyncThunk('products/fetchBots', async () => {
  return getAllBot()
})

export const { setBots } = defSlice.actions

export default defSlice.reducer
