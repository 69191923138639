import { callApi } from './config/callApi'
import { IFileEntity } from '../store/bots/bots.types'

export interface IGetBotParams {
  params: {
    id: string
  }
}

export const getBot = async (data: IGetBotParams): Promise<any> => {
  return callApi({ method: 'get', url: `bots/${data.params.id}` })
}

//  /////////////////////////////////////////////////////////////////////////
export interface ICreateBotParams {
  nickname: string
  description: string
  email: string
  is_bot: true
  bot_type: string
  social_links: {
    [key: string | 'instagram' | 'twitter' | 'youtube']: string
  }
  avatar: IFileEntity
  ai_sound?: IFileEntity
  ai_photo?: IFileEntity
  banner: IFileEntity
  language: string

  streaming_stages?: {
    stage_name: string
    description: string
    video?: IFileEntity
  }[]
}

export const createBot = async (data: ICreateBotParams): Promise<any> => {
  return callApi({ method: 'post', url: `bots`, data: data })
}

//  /////////////////////////////////////////////////////////////////////////

export interface IUpdateBotParams {
  params: {
    id: string
  }
  body: {
    nickname?: string
    description?: string
    email?: string
    is_bot?: true
    bot_type?: string
    social_links?: any
    avatar?: IFileEntity
    ai_sound?: IFileEntity
    ai_photo?: IFileEntity
    banner?: IFileEntity
    language?: string

    streaming_stages?: {
      stage_name: string
      description: string
      video: IFileEntity
    }[]
  }
}

export const updateBot = async (data: IUpdateBotParams): Promise<any> => {
  return callApi({ method: 'put', url: `bots/${data.params.id}`, data: data.body })
}

//  /////////////////////////////////////////////////////////////////////////
export interface IDeleteParams {
  params: {
    id: string
  }
}

export const deleteBot = async (data: IGetBotParams): Promise<any> => {
  return callApi({ method: 'delete', url: `bots/${data.params.id}` })
}

//  /////////////////////////////////////////////////////////////////////////

export const getAllBot = async (): Promise<any> => {
  return callApi({ method: 'get', url: `bots/all` })
}

//  /////////////////////////////////////////////////////////////////////////
