import jwt_decode from 'jwt-decode'
import { TOKEN } from '../constants/general'
import { callApi } from '../api/config/callApi'

export interface accessTokenType {
  iat: number // issue time
  exp: number // expired time
  sub: string // user ID
}

export interface ReturnType {
  usrId: string
  success: boolean
  token: string
}

const cash: { [key: string]: accessTokenType } = {}
let refreshStarted = false

const returnFail = (): Promise<ReturnType> => {
  localStorage.clear()
  sessionStorage.clear()

  return Promise.reject({
    usrId: '',
    success: false,
    token: '',
  })
}

export const getParsedAccessToken = (accessToken: string): accessTokenType => {
  if (cash[accessToken]) return cash[accessToken]
  const decodeToken: accessTokenType = jwt_decode(accessToken)

  return { ...decodeToken }
}

const refreshTokenFetch = async (refreshToken: string): Promise<ReturnType> => {
  refreshStarted = true

  try {
    const data = await callApi({
      method: 'post',
      url: '/auth/refresh',
      data: { refresh: refreshToken },
    })

    if (!data?.token || !data?.refresh) return returnFail()

    const parsedNewTokenData = getParsedAccessToken(data.token)

    if (!parsedNewTokenData.exp || !parsedNewTokenData.sub) return returnFail()

    cash[data.token] = parsedNewTokenData
    localStorage.setItem(TOKEN.access, data.token)
    localStorage.setItem(TOKEN.refresh, data.refresh)

    return {
      success: true,
      token: data.token,
      usrId: parsedNewTokenData.sub,
    }
  } catch (e: any) {
    console.error(e)

    return returnFail()
  } finally {
    refreshStarted = false
  }
}

export const refresh = async (): Promise<ReturnType> => {
  localStorage.removeItem(TOKEN.access)
  const refreshToken = localStorage.getItem(TOKEN.refresh)

  if (!refreshToken) return returnFail()

  const decodeToken: { exp?: number; created_at?: number } = jwt_decode(refreshToken)

  if (!decodeToken || !decodeToken.exp) return returnFail()

  const isExpired = Date.now() >= decodeToken.exp * 1000

  if (isExpired) return returnFail()

  if (refreshStarted) {
    return new Promise((resp) => {
      const interval = setInterval(() => {
        if (!refreshStarted) {
          const token = localStorage.getItem(TOKEN.access)
          const parsedNewTokenData = token ? getParsedAccessToken(token) : ({} as accessTokenType)

          if (!parsedNewTokenData?.exp || !parsedNewTokenData?.sub) return returnFail()
          resp(
            token
              ? {
                  token,
                  success: true,
                  usrId: parsedNewTokenData.sub,
                }
              : returnFail()
          )
          clearInterval(interval)
        }
        return null
      }, 200)
    })
  }

  return refreshTokenFetch(refreshToken)
}

export async function checkToken(): Promise<ReturnType> {
  const accessToken = localStorage.getItem(TOKEN.access)

  if (!accessToken) return refresh()

  const parsedToken = getParsedAccessToken(accessToken)

  if (!parsedToken?.exp || !parsedToken.sub) return returnFail()

  const isExpired: boolean = Date.now() >= parsedToken.exp * 1000

  if (isExpired) {
    delete cash[accessToken]
    return refresh()
  }

  if (!cash[accessToken]) cash[accessToken] = parsedToken

  return {
    success: true,
    token: accessToken,
    usrId: parsedToken.sub,
  }
}
