import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types'

const initialState: IStore['global'] = {
}

export const defSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setGlobal: (state: IStore['global'], action?) => ({ ...state, ...action?.payload }),
  },
})

export const { setGlobal } = defSlice.actions

export default defSlice.reducer
