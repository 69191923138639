import React, { useRef, useState } from 'react'
import { Box, Button, InputLabel, MenuItem, TextField, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { POPULAR_LANGUAGES } from '../../constants/general'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import { getFormProps } from '../../utils/helpers'
import { BotsTable } from '../../containers/Bots/BotsTable'
import { upload } from '../../api/storage'
import { createBot } from '../../api/bots'
import { toStreamingStages } from '../../utils/botsHelper'
import LoadingButton from '@mui/lab/LoadingButton'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'

export const BotsNew = () => {
  const navigate = useNavigate()

  const avatarRef = useRef<HTMLInputElement | null>(null)
  const bannerRef = useRef<HTMLInputElement | null>(null)

  const aiVoiceRef = useRef<HTMLInputElement | null>(null)
  const aiFaceRef = useRef<HTMLInputElement | null>(null)

  const [phrasesCount, setPhrasesCount] = useState(1)
  const [botType, setBotType] = useState<'' | 'streamer' | 'gamer'>('')

  const [loading, setLoading] = useState(false)

  const onSubmit = async (e: any) => {
    setLoading(true)
    e.preventDefault()
    const formProps = getFormProps(e)

    const [avatar, banner] = await Promise.all([upload({ file: formProps.avatar }), upload({ file: formProps.banner })])

    const [ai_sound, ai_photo] = await Promise.all([
      ...(botType === 'streamer' ? [upload({ file: formProps.ai_sound }), upload({ file: formProps.ai_photo })] : []),
    ])

    const streaming_stages = botType === 'gamer' ? await toStreamingStages(formProps) : undefined

    createBot({
      nickname: formProps.nickname,
      description: formProps.description,
      email: formProps.email,
      is_bot: true,
      bot_type: formProps.bot_type,
      social_links: { instagram: formProps.instagram, twitter: formProps.twitter, youtube: formProps.youtube },
      avatar: avatar,
      banner: banner,
      ai_sound: ai_sound,
      ai_photo: ai_photo,
      language: formProps.language,

      streaming_stages: streaming_stages,
    })
      .then(() => navigate(ROUTES.bots))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Box display='flex' justifyContent='space-between' my={4}>
        <Typography variant={'h4'}>Create Bot</Typography>

        <Button size='large' color='primary' variant='contained' startIcon={<AddIcon />}>
          New bot
        </Button>
      </Box>

      <form onSubmit={onSubmit}>
        <Box my={4}>
          <Typography variant={'h6'}>Add information</Typography>
          <Box mt={3}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <TextField required name='nickname' fullWidth label='Nickname' variant='outlined' />
              </Grid>
              <Grid item xs={4}>
                <TextField required name='email' fullWidth label='Email' type='email' variant='outlined' />
              </Grid>
              <Grid item xs={4}>
                <FormControl required fullWidth>
                  <InputLabel>Language</InputLabel>
                  <Select required defaultValue='' name='language' label='Language'>
                    {POPULAR_LANGUAGES.map((lang) => (
                      <MenuItem value={lang.code} key={lang.code}>{`${lang.name} ${lang.flag}`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl required fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    defaultValue=''
                    required
                    name='bot_type'
                    label='Type'
                    onChange={(e: any) => setBotType(e.target.value)}
                  >
                    <MenuItem value='streamer'>Streamer</MenuItem>
                    <MenuItem value='gamer'>Gamer</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl required style={{ position: 'relative' }} fullWidth>
                  <TextField
                    required
                    name='avatar'
                    inputRef={avatarRef}
                    type='file'
                    // accept='image/*'
                    helperText='Helper text'
                    className='input_file'
                    label='Avatar'
                    variant='outlined'
                  />
                  <Button
                    style={{ position: 'absolute', left: 24, top: 10 }}
                    variant='contained'
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    onClick={() => avatarRef.current?.click()}
                  >
                    Upload file
                  </Button>
                  <IconButton
                    onClick={() => (avatarRef.current ? (avatarRef.current.value = '') : null)}
                    style={{ position: 'absolute', right: 16, top: 8 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl required style={{ position: 'relative' }} fullWidth>
                  <TextField
                    required
                    name='banner'
                    inputRef={bannerRef}
                    type='file'
                    // accept='image/*'
                    helperText='Helper text'
                    className='input_file'
                    label='Banner'
                    variant='outlined'
                  />
                  <Button
                    style={{ position: 'absolute', left: 24, top: 10 }}
                    variant='contained'
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    onClick={() => bannerRef.current?.click()}
                  >
                    Upload file
                  </Button>
                  <IconButton
                    onClick={() => (bannerRef.current ? (bannerRef.current.value = '') : null)}
                    style={{ position: 'absolute', right: 16, top: 8 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField required name='description' fullWidth label='Description' variant='outlined' />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box my={4}>
          <Typography variant={'h6'}>Add social links (optional)</Typography>

          <Box mt={3}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <TextField name='instagram' fullWidth label='Instagram' variant='outlined' />
              </Grid>
              <Grid item xs={4}>
                <TextField name='twitter' fullWidth label='Twitter (X)' variant='outlined' />
              </Grid>
              <Grid item xs={4}>
                <TextField name='youtube' fullWidth label='YouTube' variant='outlined' />
              </Grid>
            </Grid>
          </Box>
        </Box>

        {botType === 'streamer' && (
          <Box my={4}>
            <Typography variant={'h6'}>Add materials for AI bot generation</Typography>

            <Box mt={3}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <FormControl required style={{ position: 'relative' }} fullWidth>
                    <TextField
                      required
                      name='ai_sound'
                      inputRef={aiVoiceRef}
                      type='file'
                      className='input_file'
                      label='Load the voice sound'
                      variant='outlined'
                    />
                    <Button
                      style={{ position: 'absolute', left: 24, top: 10 }}
                      variant='contained'
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      onClick={() => aiVoiceRef.current?.click()}
                    >
                      Upload file
                    </Button>
                    <IconButton
                      onClick={() => (aiVoiceRef.current ? (aiVoiceRef.current.value = '') : null)}
                      style={{ position: 'absolute', right: 16, top: 8 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl required style={{ position: 'relative' }} fullWidth>
                    <TextField
                      required
                      name='ai_photo'
                      inputRef={aiFaceRef}
                      type='file'
                      className='input_file'
                      label='Upload a photo of a face to generate an AI bot'
                      variant='outlined'
                    />
                    <Button
                      style={{ position: 'absolute', left: 24, top: 10 }}
                      variant='contained'
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      onClick={() => aiFaceRef.current?.click()}
                    >
                      Upload file
                    </Button>
                    <IconButton
                      onClick={() => (aiFaceRef.current ? (aiFaceRef.current.value = '') : null)}
                      style={{ position: 'absolute', right: 16, top: 8 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}

        {botType === 'gamer' && (
          <Box my={4}>
            <Typography variant={'h6'}>Add phrases</Typography>

            <Box mt={3}>
              <Grid container spacing={4}>
                {Array.from(Array(phrasesCount).keys()).map((_, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={7}>
                      <TextField
                        required
                        name={`phrase_desc${index}`}
                        fullWidth
                        label='Description'
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl required fullWidth>
                        <InputLabel>Event Stage</InputLabel>
                        <Select defaultValue='' required name={`phrase_stage${index}`} label='Event Stage'>
                          <MenuItem value='beginning'>Начало ивента</MenuItem>
                          <MenuItem value='round_start'>Запуск раунда</MenuItem>
                          <MenuItem value='pause'>Пауза между раундами</MenuItem>
                          <MenuItem value='round_in_progress'>Раунд идет</MenuItem>
                          <MenuItem value='end_of_round'>Конец раунда</MenuItem>
                          <MenuItem value='event_results'>Результаты ивента</MenuItem>
                          <MenuItem value='farewell'>Прощание</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {index === phrasesCount - 1 && (
                      <Grid item xs={2} display='grid' alignItems='center' justifyContent='flex-end'>
                        <Box display='flex' gap={2}>
                          <Button
                            variant='contained'
                            onClick={() => setPhrasesCount((p) => (p === 1 ? p : p - 1))}
                            style={{ height: 50, width: 50, minWidth: 50, background: 'rgba(211, 47, 47, 1)' }}
                          >
                            <DeleteIcon />
                          </Button>
                          <Button
                            variant='contained'
                            onClick={() => setPhrasesCount((p) => p + 1)}
                            style={{ height: 50, width: 50, minWidth: 50 }}
                          >
                            <AddIcon />
                          </Button>
                        </Box>
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          </Box>
        )}

        <Box my={4}>
          <LoadingButton
            type='submit'
            loading={loading}
            variant='contained'
            disabled={loading}
            startIcon={<DoneIcon />}
          >
            <span>Submit form</span>
          </LoadingButton>
        </Box>
      </form>

      <BotsTable />
    </>
  )
}
