import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { ROUTES } from '../../constants/routes'
import { useNavigate } from 'react-router-dom'
import { BotsTable } from '../../containers/Bots/BotsTable'

export const Bots = () => {
  const navigate = useNavigate()

  return (
    <>
      <Box display='flex' justifyContent='space-between' my={4}>
        <Typography variant={'h4'}>Bots</Typography>

        <Button
          size='large'
          color='primary'
          variant='contained'
          startIcon={<AddIcon />}
          onClick={() => navigate(ROUTES.botsNew)}
        >
          New bot
        </Button>
      </Box>
      <BotsTable />
    </>
  )
}
