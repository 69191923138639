import React from 'react'
import { Outlet } from 'react-router-dom'
import { useAppSelector } from '../hooks/useReduxHooks'
import { Navigate } from 'react-router-dom'
import { ROUTES } from '../constants/routes'

export const DefaultLayout = () => {
  const user = useAppSelector((state) => state.auth.user)

  if (user) return <Navigate replace to={ROUTES.home} />

  return (
    <>
      <Outlet />
    </>
  )
}
