import { callApi } from './config/callApi'

export interface IUploadParams {
  file: File
}

export const upload = async (data: IUploadParams): Promise<any> => {
  if (!data?.file?.size) return undefined

  const formData = new FormData()
  formData.append('file', data.file)

  return callApi({
    method: 'post',
    url: `/storage/upload`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export interface IDownloadParams {
  params: {
    key: string
  }
}

export const download = async (data: IDownloadParams): Promise<any> => {
  return callApi({ method: 'get', url: `storage/download`, params: data.params, responseType: 'blob' })
}

export interface IGetFileUrlParams {
  params: {
    key: string
  }
}

export const getFileUrl = async (data: IGetFileUrlParams): Promise<any> => {
  return callApi({ method: 'get', url: `storage/url`, params: data.params })
}
