import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, InputLabel, MenuItem, TextField, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { POPULAR_LANGUAGES } from '../../constants/general'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import { getFormProps } from '../../utils/helpers'
import { BotsTable } from '../../containers/Bots/BotsTable'
import { download, upload } from '../../api/storage'
import { deleteBot, getBot, updateBot } from '../../api/bots'
import { toStreamingStages } from '../../utils/botsHelper'
import LoadingButton from '@mui/lab/LoadingButton'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import { IBots } from '../../store/bots/bots.types'
import { downloadBlob, getFileList } from '../../utils/fileHelper'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import SendIcon from '@mui/icons-material/Send'
import { AlertDialog } from '../../components/Modal'
import Grid2 from '@mui/material/Unstable_Grid2' // Grid version 2

export const BotsEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const formRef = useRef(null)

  const avatarRef = useRef<HTMLInputElement | null>(null)
  const bannerRef = useRef<HTMLInputElement | null>(null)
  const aiVoiceRef = useRef<HTMLInputElement | null>(null)
  const aiFaceRef = useRef<HTMLInputElement | null>(null)
  const aiPhrasesRef = useRef<HTMLInputElement[]>([])

  const [phrasesCount, setPhrasesCount] = useState(1)
  const [botType, setBotType] = useState<'' | 'streamer' | 'gamer'>('')

  const [botsLoading, setBotsLoading] = useState(false)

  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IBots>()
  const [deleteDialog, setDeleteDialog] = useState(false)

  useEffect(() => {
    setBotsLoading(true)
    getBot({ params: { id: id! } })
      .then((res: IBots) => {
        setFormData(res)
        setBotType(res.bot_type)
        setPhrasesCount(res.streaming_stages?.length || 1)
        if (avatarRef.current && res?.avatar) avatarRef.current!.files = getFileList(res.avatar.originalname)
        if (bannerRef.current && res?.banner) bannerRef.current!.files = getFileList(res.banner.originalname)
      })
      .finally(() => setBotsLoading(false))
  }, [id])

  useEffect(() => {
    if (botType === 'streamer' && aiVoiceRef.current && aiFaceRef.current) {
      if (formData?.ai_sound) aiVoiceRef.current!.files = getFileList(formData.ai_sound.originalname)
      if (formData?.ai_photo) aiFaceRef.current!.files = getFileList(formData.ai_photo.originalname)
    }
  }, [botType])

  useEffect(() => {
    if (botType === 'gamer' && aiPhrasesRef.current.length) {
      formData?.streaming_stages.forEach(({ video }, index) => {
        if (aiPhrasesRef.current?.[index] && video) aiPhrasesRef.current[index].files = getFileList(video.originalname)
      })
    }
  }, [botType])

  const onDownloadAiSound = () => {
    download({ params: { key: formData!.ai_sound.key } }).then((res) => {
      downloadBlob(res, formData!.ai_sound.originalname)
    })
  }

  const onDownloadAiPhoto = () => {
    download({ params: { key: formData!.ai_photo.key } }).then((res) => {
      downloadBlob(res, formData!.ai_photo.originalname)
    })
  }

  const onDeleteBot = () => {
    deleteBot({ params: { id: id! } }).then(() => navigate(ROUTES.bots))
    setDeleteDialog(false)
  }

  const onSubmit = async (e: any) => {
    setLoading(true)
    e.preventDefault()
    const formProps = getFormProps(e)

    const [avatar, banner] = await Promise.all([upload({ file: formProps.avatar }), upload({ file: formProps.banner })])

    const [ai_sound, ai_photo] = await Promise.all([
      ...(botType === 'streamer' ? [upload({ file: formProps.ai_sound }), upload({ file: formProps.ai_photo })] : []),
    ])

    const streaming_stages =
      botType === 'gamer' ? await toStreamingStages(formProps, formData?.streaming_stages) : undefined

    updateBot({
      params: {
        id: id!,
      },
      body: {
        nickname: formProps.nickname,
        description: formProps.description,
        email: formProps.email,
        bot_type: formProps.bot_type,
        social_links: { instagram: formProps.instagram, twitter: formProps.twitter, youtube: formProps.youtube },
        avatar: avatar,
        banner: banner,
        ai_sound: ai_sound,
        ai_photo: ai_photo,
        language: formProps.language,

        streaming_stages: streaming_stages,
      },
    })
      .then(() => navigate(ROUTES.bots))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Box display='flex' justifyContent='space-between' my={4}>
        <Typography variant={'h4'}>Edit Bot</Typography>

        <Button
          size='large'
          onClick={() => navigate(ROUTES.botsNew)}
          color='primary'
          variant='contained'
          startIcon={<AddIcon />}
        >
          New bot
        </Button>
      </Box>

      <form onSubmit={onSubmit} ref={formRef}>
        <Box my={4}>
          <Typography variant={'h6'}>Add information</Typography>
          <Box mt={3}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <TextField
                  required
                  name='nickname'
                  value={formData?.nickname || ''}
                  onChange={(e) => setFormData((prev: any) => ({ ...prev, nickname: e.target.value }))}
                  fullWidth
                  label='Nickname'
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  name='email'
                  value={formData?.email || ''}
                  onChange={(e) => setFormData((prev: any) => ({ ...prev, email: e.target.value }))}
                  fullWidth
                  label='Email'
                  type='email'
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl defaultValue={formData?.language} required fullWidth>
                  <InputLabel>Language</InputLabel>
                  <Select
                    required
                    value={formData?.language || ''}
                    onChange={(e) => setFormData((prev: any) => ({ ...prev, language: e.target.value }))}
                    name='language'
                    label='Language'
                  >
                    {POPULAR_LANGUAGES.map((lang) => (
                      <MenuItem value={lang.code} key={lang.code}>{`${lang.name} ${lang.flag}`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl required fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    defaultValue=''
                    required
                    value={botType || ''}
                    name='bot_type'
                    label='Type'
                    onChange={(e: any) => setBotType(e.target.value)}
                  >
                    <MenuItem value='streamer'>Streamer</MenuItem>
                    <MenuItem value='gamer'>Gamer</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl required style={{ position: 'relative' }} fullWidth>
                  <TextField
                    required
                    name='avatar'
                    inputRef={avatarRef}
                    type='file'
                    // accept='image/*'
                    helperText='Helper text'
                    className='input_file'
                    label='Avatar'
                    variant='outlined'
                  />
                  <Button
                    style={{ position: 'absolute', left: 24, top: 10 }}
                    variant='contained'
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    onClick={() => avatarRef.current?.click()}
                  >
                    Upload file
                  </Button>
                  <IconButton
                    onClick={() => (avatarRef.current ? (avatarRef.current.value = '') : null)}
                    style={{ position: 'absolute', right: 16, top: 8 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl required style={{ position: 'relative' }} fullWidth>
                  <TextField
                    required
                    name='banner'
                    inputRef={bannerRef}
                    type='file'
                    // accept='image/*'
                    helperText='Helper text'
                    className='input_file'
                    label='Banner'
                    variant='outlined'
                  />
                  <Button
                    style={{ position: 'absolute', left: 24, top: 10 }}
                    variant='contained'
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    onClick={() => bannerRef.current?.click()}
                  >
                    Upload file
                  </Button>
                  <IconButton
                    onClick={() => (bannerRef.current ? (bannerRef.current.value = '') : null)}
                    style={{ position: 'absolute', right: 16, top: 8 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  name='description'
                  value={formData?.description || ''}
                  onChange={(e) => setFormData((prev: any) => ({ ...prev, description: e.target.value }))}
                  fullWidth
                  label='Description'
                  variant='outlined'
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box my={4}>
          <Typography variant={'h6'}>Add social links (optional)</Typography>

          <Box mt={3}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <TextField
                  name='instagram'
                  value={formData?.social_links.instagram || ''}
                  onChange={(e) =>
                    setFormData((prev: any) => ({
                      ...prev,
                      social_links: { ...prev.social_links, instagram: e.target.value },
                    }))
                  }
                  fullWidth
                  label='Instagram'
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name='twitter'
                  value={formData?.social_links.twitter || ''}
                  onChange={(e) =>
                    setFormData((prev: any) => ({
                      ...prev,
                      social_links: { ...prev.social_links, twitter: e.target.value },
                    }))
                  }
                  fullWidth
                  label='Twitter (X)'
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name='youtube'
                  value={formData?.social_links.youtube || ''}
                  onChange={(e) =>
                    setFormData((prev: any) => ({
                      ...prev,
                      social_links: { ...prev.social_links, youtube: e.target.value },
                    }))
                  }
                  fullWidth
                  label='YouTube'
                  variant='outlined'
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        {botType === 'streamer' && (
          <>
            <Box my={4}>
              <Typography variant={'h6'}>Add materials for AI bot generation</Typography>

              <Box mt={3}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <FormControl required style={{ position: 'relative' }} fullWidth>
                      <TextField
                        required
                        name='ai_sound'
                        inputRef={aiVoiceRef}
                        type='file'
                        className='input_file'
                        label='Load the voice sound'
                        variant='outlined'
                      />
                      <Button
                        style={{ position: 'absolute', left: 24, top: 10 }}
                        variant='contained'
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                        onClick={() => aiVoiceRef.current?.click()}
                      >
                        Upload file
                      </Button>
                      <IconButton
                        onClick={() => (aiVoiceRef.current ? (aiVoiceRef.current.value = '') : null)}
                        style={{ position: 'absolute', right: 16, top: 8 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl required style={{ position: 'relative' }} fullWidth>
                      <TextField
                        required
                        name='ai_photo'
                        inputRef={aiFaceRef}
                        type='file'
                        className='input_file'
                        label='Upload a photo of a face to generate an AI bot'
                        variant='outlined'
                      />
                      <Button
                        style={{ position: 'absolute', left: 24, top: 10 }}
                        variant='contained'
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                        onClick={() => aiFaceRef.current?.click()}
                      >
                        Upload file
                      </Button>
                      <IconButton
                        onClick={() => (aiFaceRef.current ? (aiFaceRef.current.value = '') : null)}
                        style={{ position: 'absolute', right: 16, top: 8 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box my={4}>
              <Typography variant={'h6'}>Download materials for AI bot generation</Typography>

              <Box mt={3}>
                <Grid container spacing={6}>
                  <Grid item display='flex' gap={1} alignItems='center'>
                    <Button variant='contained' onClick={onDownloadAiSound} startIcon={<CloudDownloadIcon />}>
                      Download file
                    </Button>
                    <Typography variant='subtitle1'>{formData?.ai_sound.originalname}</Typography>
                  </Grid>
                  <Grid item display='flex' gap={1} alignItems='center'>
                    <Button variant='contained' onClick={onDownloadAiPhoto} startIcon={<CloudDownloadIcon />}>
                      Download file
                    </Button>
                    <Typography variant='subtitle1'>{formData?.ai_photo.originalname}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        )}

        {botType === 'gamer' && (
          <Box my={4}>
            <Typography variant={'h6'}>Add phrases</Typography>

            <Box mt={3}>
              <Grid2 container spacing={4} columns={16}>
                {Array.from(Array(phrasesCount).keys()).map((_, index) => (
                  <React.Fragment key={index}>
                    <Grid2 xs={5}>
                      {!botsLoading && (
                        <TextField
                          required
                          defaultValue={formData?.streaming_stages[index]?.description}
                          name={`phrase_desc${index}`}
                          fullWidth
                          label='Description'
                          variant='outlined'
                        />
                      )}
                    </Grid2>
                    <Grid2 xs={4}>
                      {!botsLoading && (
                        <FormControl required fullWidth>
                          <InputLabel>Event Stage</InputLabel>
                          <Select
                            defaultValue={formData?.streaming_stages[index]?.stage_name || ''}
                            required
                            name={`phrase_stage${index}`}
                            label='Event Stage'
                          >
                            <MenuItem value='beginning'>Начало ивента</MenuItem>
                            <MenuItem value='round_start'>Запуск раунда</MenuItem>
                            <MenuItem value='pause'>Пауза между раундами</MenuItem>
                            <MenuItem value='round_in_progress'>Раунд идет</MenuItem>
                            <MenuItem value='end_of_round'>Конец раунда</MenuItem>
                            <MenuItem value='event_results'>Результаты ивента</MenuItem>
                            <MenuItem value='farewell'>Прощание</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Grid2>
                    <Grid2 xs={5}>
                      <FormControl required style={{ position: 'relative' }} fullWidth>
                        <TextField
                          required
                          name={`phrase_file${index}`}
                          inputRef={(ref) => (aiPhrasesRef.current[index] = ref)}
                          type='file'
                          className='input_file'
                          label='Load the voice sound'
                          variant='outlined'
                        />
                        <Button
                          style={{ position: 'absolute', left: 24, top: 10 }}
                          variant='contained'
                          tabIndex={-1}
                          startIcon={<CloudUploadIcon />}
                          onClick={() => aiPhrasesRef.current[index]?.click()}
                        >
                          Upload file
                        </Button>
                        <IconButton
                          onClick={() => {
                            aiPhrasesRef.current[index] ? (aiPhrasesRef.current[index].value = '') : null
                          }}
                          style={{ position: 'absolute', right: 16, top: 8 }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </FormControl>
                    </Grid2>
                    {index === phrasesCount - 1 && (
                      <Grid2 xs={2} display='grid' alignItems='center' justifyContent='flex-end'>
                        <Box display='flex' gap={2}>
                          <Button
                            variant='contained'
                            onClick={() => setPhrasesCount((p) => (p === 1 ? p : p - 1))}
                            style={{ height: 50, width: 50, minWidth: 50, background: 'rgba(211, 47, 47, 1)' }}
                          >
                            <DeleteIcon />
                          </Button>
                          <Button
                            variant='contained'
                            onClick={() => setPhrasesCount((p) => p + 1)}
                            style={{ height: 50, width: 50, minWidth: 50 }}
                          >
                            <AddIcon />
                          </Button>
                        </Box>
                      </Grid2>
                    )}
                  </React.Fragment>
                ))}
              </Grid2>
            </Box>
          </Box>
        )}

        <Box my={4} display='flex' flexDirection='row' gap={2}>
          <LoadingButton variant='contained' startIcon={<SendIcon />}>
            <span>Publish</span>
          </LoadingButton>

          <LoadingButton type='submit' loading={loading} variant='outlined' disabled={loading} startIcon={<DoneIcon />}>
            <span>Save to draft</span>
          </LoadingButton>

          <LoadingButton
            variant='outlined'
            color='error'
            onClick={() => setDeleteDialog(true)}
            startIcon={<DeleteIcon />}
          >
            <span>Delete</span>
          </LoadingButton>
        </Box>
      </form>
      <AlertDialog open={deleteDialog} setOpen={setDeleteDialog} onAgree={onDeleteBot}>
        Are you sure you want to delete the BOT?
      </AlertDialog>

      <BotsTable />
    </>
  )
}
