import { Avatar, Box, Card, CardContent, CardMedia, Chip, Tab, Tabs, Typography } from '@mui/material'
import { UserMain } from '../../containers/Users/UserMain'
import React, { useState } from 'react'
// Import css files
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Slider, { Settings } from 'react-slick'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { a11yProps, CustomTabPanel } from '../../components/CustomTabPanel'
import { SliderWrapper } from '../../components/SliderWrapper'

const settings: Settings = {
  dots: false,
  infinite: false,
  draggable: false,
  prevArrow: <KeyboardArrowLeftIcon sx={{ fill: 'rgba(0,0,0,0.34)', left: '-45px' }} />,
  nextArrow: <KeyboardArrowRightIcon sx={{ fill: 'rgba(0,0,0,0.34)', right: '-45px' }} />,
  speed: 400,
  slidesToShow: 7,
  slidesToScroll: 1,
}

const settings2: Settings = {
  dots: false,
  infinite: false,
  draggable: false,
  prevArrow: <KeyboardArrowLeftIcon sx={{ fill: 'rgba(0,0,0,0.34)', left: '-25px' }} />,
  nextArrow: <KeyboardArrowRightIcon sx={{ fill: 'rgba(0,0,0,0.34)', right: '-25px' }} />,
  speed: 400,
  slidesToShow: 4,
  slidesToScroll: 1,
}

export const Player = () => {
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue)

  return (
    <>
      <UserMain />
      <Box my={4}>
        <Card>
          <CardContent>
            <Typography variant='subtitle1'>Installed games:</Typography>

            <SliderWrapper height={80} px={6}>
              <Slider {...settings}>
                {Array.from(Array(16).keys()).map((value, index) => (
                  <Box key={value}>
                    <Box display='flex' alignItems='center' whiteSpace='nowrap' gap={1}>
                      <Avatar
                        alt='Remy Sharp'
                        src='https://s3-alpha-sig.figma.com/img/3d13/5909/dd1e02930e1107125d05dc292870e9e2?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=o8TgpduUPXpAF218AgCYqVTK5Jl2gFNFbgSUIyClzzwKEEZRJcaSHgzLf8v6KKmn5bCNKfQhMeLgLi7444PZ6~xNNQ44PV3mAU8PiaEP-XhX2EsTlrMVnSL0MmffjwHi7jDSZHgk9fuTyc-FBCibSrb-Or14p7mW4yc4Lz1d1ypKiE3DQFIBJSWt0Qf6SwkVCU6Q~fW8iklwpIC47lrnC5yy9G0PksEDxPWcZxG5liBAGJom3P5tCNCzDUaWfi0GSyMZrWuDr4eUrKUOB8DyfBM6YRuiirJ2RVTb8QXZ2acfTMmSX-HhtXMNcTjTgBB0X0JF~umSNyPBT0DL0X8yXw'
                        sx={{ width: 40, height: 40 }}
                      />
                      <Typography variant='body2'>Battle Runner</Typography>
                    </Box>
                  </Box>
                ))}
              </Slider>
            </SliderWrapper>
          </CardContent>
        </Card>
      </Box>

      <Box my={4}>
        <Card>
          <CardContent>
            <Typography variant='subtitle1'>Follows on:</Typography>

            <SliderWrapper height={80} px={6}>
              <Slider {...settings}>
                {Array.from(Array(5).keys()).map((value, index) => (
                  <Box key={value}>
                    <Box display='flex' alignItems='center' whiteSpace='nowrap' gap={1}>
                      <Avatar
                        alt='Remy Sharp'
                        src='https://s3-alpha-sig.figma.com/img/e9f2/ccec/ff48c73011fb6a9c6d337bd1000178e4?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Bfa5ZyQmddbxpMg6Hpkt-24wCX0~3ICOWx9OeFlymAafPuOkIpc1QYkNz4-2xVfTBX5cWHEd0BEfrJAOscPqDaRjKYT1dHXhJ1L5ZKIctTLcmcBRSs4VVqHwa0zX8IiZe34YjIwoDKGx4HWU37WDsTQrzAwCnjbxuTvO6N9mGSeLQmJmKo~tjvJDtvC0TAfVov78v50drkdLTHEIaF~8v1Fu4UFwvE7sD0hqR69wjFY~-C~z6qzCt9WJI5o2FM5U58dLIrNF5YoSasBNq~spAFuMu9fRr399fdRHFJ8kib5ke9kTDTH87q74msUkotG7vEST3UzmXZRhIzFfvBePAw'
                        sx={{ width: 40, height: 40 }}
                      />
                      <Typography variant='body2'>Battle Runner</Typography>
                    </Box>
                  </Box>
                ))}
              </Slider>
            </SliderWrapper>
          </CardContent>
        </Card>
      </Box>

      <Box my={4}>
        <Card>
          <CardContent>
            <Typography variant='subtitle1'>Subscribe on:</Typography>
            <SliderWrapper height={80} px={6}>
              <Slider {...settings}>
                {Array.from(Array(6).keys()).map((value, index) => (
                  <Box key={value}>
                    <Box display='flex' alignItems='center' whiteSpace='nowrap' gap={1}>
                      <Avatar
                        alt='Remy Sharp'
                        src='https://s3-alpha-sig.figma.com/img/872f/268b/64576b7d90a979bb57fb80d33272b843?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nq8gsJX7aytzVrRYi0PTg4y~HtU8FzO72Esvdw1AtrR92SaOrgB~D6ckUT9arwXdSTN5Zhv9NxF9A2SYq3P8e6DzeRofDiXZvq-KBPTjQkJfweq8Rfn3t9wExU14x4I9XgxM3NQu5lBVHljt2zskupaxrBhi2HNN5~B1UG4Sedr9vEWFRjIp5uo4njGari5GTWZ56jBGgqUHq7T72jNm8kNMy0UlM43liqqIvBSIOvOtu32Bv~M-VdosgJepu4oFnRv2OB9xIEP5vWIETBun~l6iCkHkRkjjgDLSl7Qyu9fMaE0Rp~fCpaB8kRBHC1c6CSZbOnc6-5BPM5OBokT8BQ'
                        sx={{ width: 40, height: 40 }}
                      />
                      <Typography variant='body2'>Battle Runner</Typography>
                    </Box>
                  </Box>
                ))}
              </Slider>
            </SliderWrapper>
          </CardContent>
        </Card>
      </Box>

      <Box my={4}>
        <Card>
          <CardContent>
            <Typography variant='subtitle1'>Grievances:</Typography>

            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                  <Tab label='Incoming' {...a11yProps(0)} />
                  <Tab label='Outgoing' {...a11yProps(1)} />
                </Tabs>
              </Box>

              <CustomTabPanel value={value} index={0}>
                <SliderWrapper height={304} px={2}>
                  <Slider {...settings2}>
                    {Array.from(Array(6).keys()).map((value, index) => (
                      <Box key={value}>
                        <Box display='flex' justifyContent='center'>
                          <Card style={{ width: 254 }} variant='outlined'>
                            <Box p={4} display='flex' flexDirection='column' gap={1}>
                              <Typography variant='body1'>John Doe • 4 Feb 2022</Typography>
                              <Typography variant='h6'>Grievance</Typography>
                              <Typography variant='body1'>
                                It is a long established fact that a reader will be distracted by the readable content
                                of a page when looking at its layout.
                              </Typography>

                              <Chip
                                style={{ width: 'fit-content' }}
                                label={'Lillian Jameson'}
                                icon={
                                  <Avatar
                                    src='https://s3-alpha-sig.figma.com/img/bdc6/db2a/3a906b3de8eaa53e14582edf5c918b5d?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=XRvZQL-92C9SsEbVh0zO7sSIrADRaqPPkd5LCm9UC9-U06xlbk8VYOgnIkeRPOGdddpxE4FoeMHtExv2mHjbiRZcp75BeUwRcsk9sKfrP6D1pE1-aErkhExTHWW9TSCtQbE~O2lx03RgsZqQkvarLsiHyuW7FrvhDGjT~JNH-9EZyUqeUGa4jgKYanb2Nj81GjNLscMA7GcI2ZTlGfTfl9eEKymSJnyna6LTnE1wGdxMToTI70p~RF3xBLzOG9TIHHysHXo0aDRPJhLq3aRK0mYNkMA~YeVBpEc14L2KRo4vMbTLt88orxUeH4kQLIncE88AMEKEvg-DLreSC9KD~g__'
                                    style={{ width: 20, height: 20 }}
                                    alt='Avatar'
                                    variant='circular'
                                  />
                                }
                              />
                            </Box>
                          </Card>
                        </Box>
                      </Box>
                    ))}
                  </Slider>
                </SliderWrapper>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <SliderWrapper height={304} px={2}>
                  <Slider {...settings2}>
                    {Array.from(Array(6).keys()).map((value, index) => (
                      <Box key={value}>
                        <Box display='flex' justifyContent='center'>
                          <Card style={{ width: 254 }} variant='outlined'>
                            <Box p={4} display='flex' flexDirection='column' gap={1}>
                              <Typography variant='body1'>John Doe • 4 Feb 2022</Typography>
                              <Typography variant='h6'>Grievance</Typography>
                              <Typography variant='body1'>
                                It is a long established fact that a reader will be distracted by the readable content
                                of a page when looking at its layout.
                              </Typography>

                              <Chip
                                style={{ width: 'fit-content' }}
                                label={'Lillian Jameson'}
                                icon={
                                  <Avatar
                                    src='https://s3-alpha-sig.figma.com/img/e2e6/a5d9/df87fbcb18ff78fa3ee65b56f6f56b4a?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Tq1m5GWXq2lfnXaQkKNXefpDj8XO1ngQa4On6YhN-SncbDKKHfO9Guls85aggH4ZDvMope8DOCsYUGsSKGHROSWaPhNRdJWaVji~FxsT2mLb5iFWdmusTCIE~bOe1lbb1~AEEIu2nP8pD5JWQWMk0qzr60bkhFYaEzVmVub9N4cZdXG0yjIuzTdwZuSyFF5d7lL0xRSA50OeK7QhCy51WpKUpF6GYwtEq01iNJYCl~opAa-QCHEBQ5cpg5Zv92B7VYv-cZzXH-iTpX7MmeGekNaThd2oSvubB32gWuxf918GWVYfhT8xV5X8Nb6I6TqP2p11BcVPvsZM0atH3ivKcg__'
                                    style={{ width: 20, height: 20 }}
                                    alt='Avatar'
                                    variant='circular'
                                  />
                                }
                              />
                            </Box>
                          </Card>
                        </Box>
                      </Box>
                    ))}
                  </Slider>
                </SliderWrapper>
              </CustomTabPanel>
            </Box>
          </CardContent>
        </Card>
      </Box>

      <Box my={4}>
        <Card>
          <CardContent>
            <Typography variant='subtitle1'>Planned events:</Typography>

            <SliderWrapper height={364} px={6}>
              <Slider {...settings2}>
                {Array.from(Array(10).keys()).map((value, index) => (
                  <Box key={value}>
                    <Box display='flex' justifyContent='center'>
                      <Card style={{ width: 254 }} variant='outlined'>
                        <CardMedia
                          sx={{ height: 140 }}
                          image='https://www.figma.com/file/d2UhCkBLMudIayc9p6iHgx/image/e2e6a5d9df87fbcb18ff78fa3ee65b56f6f56b4a'
                          title='green iguana'
                        />
                        <CardContent>
                          <Box display='flex' flexDirection='column' gap={1}>
                            <Typography variant='subtitle2' color='#2196F3'>
                              10:00 AM • 4 Jun 2024
                            </Typography>
                            <Typography variant='h6'>Awesome Event</Typography>
                            <Typography variant='body1'>
                              We introduce you our new event! Play & win presents!
                            </Typography>

                            <Chip
                              sx={{ width: 'fit-content', background: 'transparent' }}
                              label={'Prabodhan Fitzgerald'}
                              icon={
                                <Avatar
                                  src='https://s3-alpha-sig.figma.com/img/872f/268b/64576b7d90a979bb57fb80d33272b843?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nq8gsJX7aytzVrRYi0PTg4y~HtU8FzO72Esvdw1AtrR92SaOrgB~D6ckUT9arwXdSTN5Zhv9NxF9A2SYq3P8e6DzeRofDiXZvq-KBPTjQkJfweq8Rfn3t9wExU14x4I9XgxM3NQu5lBVHljt2zskupaxrBhi2HNN5~B1UG4Sedr9vEWFRjIp5uo4njGari5GTWZ56jBGgqUHq7T72jNm8kNMy0UlM43liqqIvBSIOvOtu32Bv~M-VdosgJepu4oFnRv2OB9xIEP5vWIETBun~l6iCkHkRkjjgDLSl7Qyu9fMaE0Rp~fCpaB8kRBHC1c6CSZbOnc6-5BPM5OBokT8BQ__'
                                  style={{ width: 30, height: 30, marginLeft: 0 }}
                                  alt='Avatar'
                                  variant='circular'
                                />
                              }
                            />
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                  </Box>
                ))}
              </Slider>
            </SliderWrapper>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}
