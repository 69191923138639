export const getFileList = (name: string) => {
  const dataTransfer = new DataTransfer()
  const myFile = new File([], name)
  dataTransfer.items.add(myFile)
  return dataTransfer.files
}

export const downloadBlob = (blob: Blob, name = 'file.txt') => {
  const blobUrl = URL.createObjectURL(blob)
  const link = document.createElement('a')

  link.href = blobUrl
  link.download = name

  document.body.appendChild(link)

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  )

  document.body.removeChild(link)
}
