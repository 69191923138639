import React, { useState } from 'react'
import { Avatar, Box, Button, Chip, Grid, InputLabel, MenuItem, Stack, Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { BarChart } from '@mui/x-charts/BarChart'
import Paper from '@mui/material/Paper'

const barChartsParams = {
  series: [
    {
      id: 'series-1',
      data: [3, 4, 1, 6, 5, 3, 4, 1, 6, 5, 7, 8],
      label: 'Online',
      stack: 'total',
      highlightScope: {
        highlighted: 'item',
      },
    },
    {
      id: 'series-2',
      data: [4, 3, 1, 5, 8, 3, 4, 1, 6, 5, 7, 3],
      label: 'Playing games',
      stack: 'total',
      highlightScope: {
        highlighted: 'item',
      },
    },
  ],
  xAxis: [
    {
      data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      scaleType: 'band',
      id: 'axis1',
    },
  ],
  height: 400,
}

export const Dashboard = () => {
  const [date, setDate] = useState('')

  return (
    <>
      <Box display='flex' justifyContent='space-between' my={4}>
        <Typography variant={'h4'}>Dashboard</Typography>

        <Box>
          <Grid container spacing={1} width={450}>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Select date</InputLabel>
                <Select
                  label='Select date'
                  labelId='demo-simple-select-label'
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                >
                  <MenuItem value={1}>Today</MenuItem>
                  <MenuItem value={7}>Last week</MenuItem>
                  <MenuItem value={30}>last month</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Box display='flex' justifyContent='space-around'>
                <Button variant='contained' style={{ height: 54, width: 54, minWidth: 54 }}>
                  <FilterAltIcon />
                </Button>
                <Button variant='contained' style={{ height: 54, width: 54, minWidth: 54 }}>
                  <SwapHorizIcon />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box my={5}>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Card>
              <CardContent>
                <Typography variant='h4'>$24.000</Typography>
                <Typography variant='subtitle1' my={2}>
                  Money from Battle Coins
                </Typography>
                <Box display='flex' alignItems='center' gap={1}>
                  <Chip label='+5.35%' color='success' />
                  <Typography variant='body1'>Money from Battle Coins</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card>
              <CardContent>
                <Typography variant='h4'>23 212</Typography>
                <Typography variant='subtitle1' my={2}>
                  Registered players
                </Typography>
                <Box display='flex' alignItems='center' gap={1}>
                  <Chip label='-2.23%' color='error' />
                  <Typography variant='body1'>Money from Battle Coins</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card>
              <CardContent>
                <Typography variant='h4'>481</Typography>
                <Typography variant='subtitle1' my={2}>
                  Bloggers
                </Typography>
                <Box display='flex' alignItems='center' gap={1}>
                  <Chip label='+5.35%' color='success' />
                  <Typography variant='body1'>Money from Battle Coins</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card>
              <CardContent>
                <Typography variant='h4'>962</Typography>
                <Typography variant='subtitle1' my={2}>
                  Events planned
                </Typography>
                <Box display='flex' alignItems='center' gap={1}>
                  <Chip label='+5.35%' color='success' />
                  <Typography variant='body1'>Money from Battle Coins</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Box my={5}>
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <Card>
              <CardContent>
                <Typography variant='h5' mb={4}>
                  Players online
                </Typography>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 0, md: 4 }} sx={{ width: '100%' }}>
                  <Box sx={{ flexGrow: 1 }}>
                    {/*@ts-ignore*/}
                    <BarChart
                      {...barChartsParams}
                      // onItemClick={(event: any, d: any) => setItemData(d)}
                      // onAxisClick={(event: any, d: any) => setAxisData(d)}
                    />
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={{ height: '100%', position: 'relative' }}>
              <CardContent style={{ maxHeight: 450, overflow: 'auto' }}>
                <Box display='flex' justifyContent='space-between'>
                  <Typography variant='h5' mb={4}>
                    Games feedbacks
                  </Typography>
                  <Chip label='Today' color='primary' />
                </Box>

                <Box gap={2} display='flex' flexDirection='column'>
                  <Paper style={{ padding: 12, display: 'flex', gap: 8, flexDirection: 'column' }}>
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                      <Box display='flex' alignItems='center' gap={2}>
                        <Avatar variant='square'>OP</Avatar>
                        <Typography variant='h6'>Office President</Typography>
                      </Box>
                      <Typography variant='subtitle2' color='#2196F3'>
                        4 Feb 2022
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant='body1'>
                        It is a long established fact that a reader will be distracted by the readable content of a page
                        when looking at its layout.
                      </Typography>
                    </Box>
                  </Paper>
                  <Paper style={{ padding: 12, display: 'flex', gap: 8, flexDirection: 'column' }}>
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                      <Box display='flex' alignItems='center' gap={2}>
                        <Avatar variant='square'>OP</Avatar>
                        <Typography variant='h6'>Office President</Typography>
                      </Box>
                      <Typography variant='subtitle2' color='#2196F3'>
                        4 Feb 2022
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant='body1'>
                        It is a long established fact that a reader will be distracted by the readable content of a page
                        when looking at its layout.
                      </Typography>
                    </Box>
                  </Paper>
                  <Paper style={{ padding: 12, display: 'flex', gap: 8, flexDirection: 'column' }}>
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                      <Box display='flex' alignItems='center' gap={2}>
                        <Avatar variant='square'>OP</Avatar>
                        <Typography variant='h6'>Office President</Typography>
                      </Box>
                      <Typography variant='subtitle2' color='#2196F3'>
                        4 Feb 2022
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant='body1'>
                        It is a long established fact that a reader will be distracted by the readable content of a page
                        when looking at its layout.
                      </Typography>
                    </Box>
                  </Paper>
                </Box>
              </CardContent>
              <Box height={50} />

              <Box position='absolute' bottom={10} left={16} right={16}>
                <Button variant='contained' fullWidth>
                  Load More
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
