import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  typography: {
    body1: {
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '150%',
      letterSpacing: '0.15px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    body2: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      lineHeight: '143%',
      letterSpacing: '0.17px',
    },
    subtitle1: {
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '175%',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '157%',
      letterSpacing: '0.1px',
    },
    overline: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '266%',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '166%',
      letterSpacing: '0.4px',
    },
    h6: {
      fontFamily: 'Roboto',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '160%',
      letterSpacing: '0.15px',
    },
    h5: {
      fontFamily: 'Roboto',
      fontSize: '24px',
      lineHeight: '133.4%',
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.87)',
    },
    h4: {
      fontFamily: 'Roboto',
      fontSize: '34px',
      fontWeight: 400,
      lineHeight: '123.5%',
      letterSpacing: '0.25px',
    },
    h3: {
      fontFamily: 'Roboto',
      fontSize: '48px',
      fontWeight: 400,
      lineHeight: '116.7%',
    },
    h2: {
      fontFamily: 'Roboto',
      fontSize: '60px',
      fontWeight: 300,
      lineHeight: '120%',
      letterSpacing: '-0.5px',
    },
    h1: {
      fontFamily: 'Roboto',
      fontSize: '96px',
      fontWeight: 300,
      lineHeight: '116.7%',
      letterSpacing: '-1.5px',
    },
    custom: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '171%',
      letterSpacing: '0.17px',
    },
  },
})
