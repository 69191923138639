import { upload } from '../api/storage'
import { IStreamingStages } from '../store/bots/bots.types'

export const toStreamingStages = async (obj: any, stages?: IStreamingStages[]) => {
  const streaming_stages = []
  const files = []

  let i = 0
  while (obj[`phrase_stage${i}`] !== undefined) {
    const stage_name = obj[`phrase_stage${i}`] || ''
    const description = obj[`phrase_desc${i}`] || ''
    const file = obj[`phrase_file${i}`] || ''

    files.push(file)

    streaming_stages.push({ stage_name, description })
    i++
  }

  const uploadedFiles = await Promise.all(files.map((file) => upload({ file: file })))

  return streaming_stages.map((props, index) => ({
    ...props,
    video: uploadedFiles[index] || stages?.[index].video,
  }))
}
