export const ROUTES = {
  home: '/',
  dashboard: '/dashboard',

  bots: '/bots',
  botsNew: `/bots/new`,
  botsEdit: `/bots/:id`,

  games: '/games',

  users: '/users',
  usersPlayer: '/users/player',

  login: '/login',
  register: '/register',
}
