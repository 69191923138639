export const getError = (e: any) => {
  return (
    e?.response?.data?.message?.[0]?.message ||
    e?.response?.data?.message ||
    e?.response?.data?.error ||
    e?.response?.statusText ||
    e?.message ||
    'error'
  )
}
