import { AxiosRequestConfig } from 'axios'
import { checkToken, refresh, ReturnType } from '../../utils/checkToken'
import axios from './axios'
import { showMessage } from '../../components/Alert'
import { getError } from '../../utils/error'

interface IConfig extends AxiosRequestConfig<any> {}

export async function callApi(config: IConfig, showError: boolean = true): Promise<any> {
  const mustCheckToken = !['/auth/register', '/auth/login', '/auth/refresh'].some((p) => config.url?.includes(p))

  let userToken: ReturnType = {
    usrId: '',
    success: true,
    token: '',
  }
  if (mustCheckToken) {
    userToken = await checkToken()
  }

  if (!userToken.success) return (window.location.href = '/login#sessionisexpired')

  const clearStorage = () => {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = '/login#endofsession'
    return null
  }

  const request = async (access?: string): Promise<any> => {
    const tryRefreshToken = async (err: any) => {
      try {
        const resp = await refresh()
        if (resp.success) return request(resp.token)
      } catch (e: any) {
        clearStorage()

        showError && showMessage('error', getError(err))

        throw err?.response || err
      }
    }

    try {
      const res = await axios.request({
        ...config,
        paramsSerializer: {
          indexes: null,
        },
        headers: {
          Authorization: `Bearer ${access || userToken.token}`,
          'Content-Type': 'application/json',
          decompress: false,
          ...config.headers,
        },
      })

      return res.data
    } catch (e: any) {
      if (e?.response?.status === 401) {
        if (!access) return tryRefreshToken(e)
        clearStorage()
      }

      showError && showMessage('error', getError(e))

      throw e?.response || e
    }
  }

  return request()
}
