import { Alert } from '@mui/material'
import React from 'react'
import { createRoot } from 'react-dom/client'

let timerId: any = null
const containers: HTMLDivElement[] = []

export const showMessage = (messageType: 'success' | 'info' | 'warning' | 'error', message: string) => {
  clearTimeout(timerId)
  containers.map((container) => container.remove())
  const container = document.createElement('div')

  container.style.position = 'fixed'
  container.style.zIndex = '10000'
  container.style.top = '10vh'
  container.style.right = '50px'
  container.style.minWidth = '260px'
  container.style.transitionDuration = '1000ms'
  container.style.opacity = '0'
  container.className = `transition-all`
  if (window.innerWidth < 450) {
    container.style.right = '5px'
    container.style.left = '5px'
  }

  setTimeout(() => {
    container.style.opacity = '1'
  }, 0)

  timerId = setTimeout(() => {
    container.style.right = '-500px'
    if (window.innerWidth < 450) {
      container.style.left = '1000px'
    }
    setTimeout(() => {
      container.remove()
    }, 500)
  }, 5000)
  containers.push(container)

  const root = createRoot(document.body.appendChild(container))

  root.render(
    <Alert onClose={() => container.remove()} severity={messageType} variant='filled' sx={{ width: '100%' }}>
      {message.toString()}
    </Alert>
  )
}
