import React, { useEffect } from 'react'
import { Avatar, Box, Chip, Grid, IconButton } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../hooks/useReduxHooks'
import { fetchBots } from '../../store/bots/bots.slice'
import { DataGrid } from '@mui/x-data-grid'
import { TableNoDataOverlay } from '../../components/TableNoDataOverlay'
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef'
import { PlayArrow } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'

const columns: readonly GridColDef[] = [
  {
    field: 'nickname',
    headerName: 'Nickname',
    flex: 2,

    renderCell: (p) => (
      <Box display='flex' gap={1} alignItems='center'>
        <Avatar alt='Avatar' src={p.row?.['avatar']?.url} variant='circular' />
        {p.value}
      </Box>
    ),
  },
  {
    field: 'id',
    headerName: 'ID',
    flex: 2,

    renderCell: (p) => <Link to={`${ROUTES.bots}/${p.value}`}>{p.value}</Link>,
  },
  {
    field: 'GamesPlayed',
    headerName: 'Games played',
    flex: 1,
  },
  {
    field: 'bot_type',
    headerName: 'Type',
    flex: 1,

    renderCell: (p) => (
      <Chip
        label={p.value}
        color={p.value === 'streamer' ? 'warning' : 'default'}
        icon={
          <Avatar
            style={{
              width: 20,
              height: 20,
              fontSize: 12,
              color: '#fff',
              background: p.value === 'streamer' ? '#E65100' : '',
            }}
            alt='Avatar'
            variant='circular'
          >
            OP
          </Avatar>
        }
      />
    ),
  },
  {
    field: 'is_bot',
    headerName: 'Status',
    flex: 1,

    renderCell: (p) => (p.value ? 'Unpublished' : 'Published'),
  },
  {
    field: 'action',
    headerName: 'Action',

    sortable: false,
    renderCell: (p) => (
      <IconButton color='primary'>
        <PlayArrow />
      </IconButton>
    ),
  },
]

export const BotsTable = () => {
  const dispatch = useAppDispatch()
  const bots = useAppSelector((state) => state.bots)

  useEffect(() => {
    dispatch(fetchBots())
  }, [])

  return (
    <Box minHeight={600}>
      <Grid container>
        <Grid item xs={12}>
          <DataGrid
            columns={columns}
            disableColumnMenu
            autoHeight
            disableRowSelectionOnClick
            sortingOrder={['asc', 'desc']}
            rows={bots.data || []}
            slots={{
              // loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
              noRowsOverlay: TableNoDataOverlay,
            }}
            loading={bots.loading}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
