import React from 'react'

export const GoogleIcon = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_250_12998)'>
        <path
          d='M6.64863 18.1293L5.60437 22.0277L1.78764 22.1084C0.646992 19.9928 0 17.5722 0 14.9999C0 12.5126 0.604922 10.1669 1.67719 8.10156H1.67801L5.07598 8.72453L6.56449 12.1021C6.25295 13.0104 6.08314 13.9854 6.08314 14.9999C6.08326 16.101 6.28271 17.156 6.64863 18.1293Z'
          fill='#FBBB00'
        />
        <path
          d='M29.7382 12.1978C29.9105 13.1051 30.0003 14.0422 30.0003 14.9999C30.0003 16.0738 29.8874 17.1214 29.6723 18.1318C28.9421 21.5703 27.0341 24.5728 24.391 26.6975L24.3902 26.6967L20.1103 26.4783L19.5045 22.6969C21.2584 21.6684 22.629 20.0587 23.351 18.1318H15.3301V12.1978H23.468H29.7382Z'
          fill='#518EF8'
        />
        <path
          d='M24.3893 26.6967L24.3901 26.6975C21.8196 28.7637 18.5541 30 14.9995 30C9.28711 30 4.32064 26.8071 1.78711 22.1085L6.64811 18.1294C7.91484 21.5101 11.1761 23.9168 14.9995 23.9168C16.6429 23.9168 18.1825 23.4725 19.5036 22.697L24.3893 26.6967Z'
          fill='#28B446'
        />
        <path
          d='M24.575 3.45328L19.7156 7.43156C18.3484 6.57691 16.7321 6.0832 15.0005 6.0832C11.0906 6.0832 7.76838 8.60021 6.5651 12.1022L1.67855 8.10164H1.67773C4.17418 3.28846 9.20328 0 15.0005 0C18.6401 0 21.9772 1.29645 24.575 3.45328Z'
          fill='#F14336'
        />
      </g>
      <defs>
        <clipPath id='clip0_250_12998'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
