import { Container, CssBaseline, Box, Typography, TextField, Button, Grid } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../constants/routes'
import { login } from '../api/auth'
import { TOKEN } from '../constants/general'
import { useAppDispatch } from '../hooks/useReduxHooks'
import { setAuth } from '../store/auth/auth.slice'

export const Login = () => {
  const dispatch = useAppDispatch()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [loading, setLoading] = useState(false)

  const handleLogin = () => {
    setLoading(true)

    login({ password: password, email: email })
      .then(({ token, refresh, user }) => {
        localStorage.setItem(TOKEN.access, token)
        localStorage.setItem(TOKEN.refresh, refresh)
        dispatch(setAuth({ user: user }))
      })
      .catch(() => setLoading(false))
  }

  return (
    <>
      <Container maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography variant='h5'>Log In</Typography>
          <Typography variant='body1'> Get started for free</Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email'
              name='email'
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              margin='normal'
              required
              fullWidth
              id='password'
              name='password'
              label='Password'
              type='password'
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />

            <Button disabled={loading} fullWidth variant='contained' sx={{ mt: 3, mb: 2 }} onClick={handleLogin}>
              Login
            </Button>
            <Grid container>
              <Grid item>
                <Link to={ROUTES.register}>Don&apos;t have an account? Register</Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  )
}
