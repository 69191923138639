export const TOKEN = {
  access: process.env.REACT_APP_ACCESS_TOKEN || 'X-Access-Token',
  refresh: process.env.REACT_APP_REFRESH_TOKEN || 'X-Refresh-Token',
}

export const POPULAR_LANGUAGES = [
  { name: 'English', code: 'en', flag: '🇬🇧' },
  { name: 'Ukrainian', code: 'uk', flag: '🇺🇦' },
  { name: 'Russian', code: 'ru', flag: '🇷🇺' },
  { name: 'Spanish', code: 'es', flag: '🇪🇸' },
  { name: 'French', code: 'fr', flag: '🇫🇷' },
  { name: 'German', code: 'de', flag: '🇩🇪' },
  { name: 'Portuguese', code: 'pt', flag: '🇵🇹' },
  { name: 'Mandarin Chinese', code: 'zh', flag: '🇨🇳' },
  { name: 'Hindi', code: 'hi', flag: '🇮🇳' },
  { name: 'Standard Arabic', code: 'ar', flag: '🇸🇦' },
  { name: 'Bengali', code: 'bn', flag: '🇧🇩' },
  { name: 'Turkish', code: 'tr', flag: '🇹🇷' },
  { name: 'Korean', code: 'ko', flag: '🇰🇷' },
  { name: 'Japanese', code: 'ja', flag: '🇯🇵' },
  { name: 'Indonesian', code: 'id', flag: '🇮🇩' },
]

export const LANGUAGES = [
  { name: 'Abkhazian', code: 'ab', flag: '🇬🇪' },
  { name: 'Afar', code: 'aa', flag: '🇩🇯' },
  { name: 'Afrikaans', code: 'af', flag: '🇿🇦' },
  { name: 'Akan', code: 'ak', flag: '🇬🇭' },
  { name: 'Albanian', code: 'sq', flag: '🇦🇱' },
  { name: 'Amharic', code: 'am', flag: '🇪🇹' },
  { name: 'Arabic', code: 'ar', flag: '🇸🇦' },
  { name: 'Aragonese', code: 'an', flag: '🇪🇸' },
  { name: 'Armenian', code: 'hy', flag: '🇦🇲' },
  { name: 'Assamese', code: 'as', flag: '🇮🇳' },
  { name: 'Avaric', code: 'av', flag: '🇷🇺' },
  { name: 'Avestan', code: 'ae', flag: '🇮🇷' },
  { name: 'Aymara', code: 'ay', flag: '🇧🇴' },
  { name: 'Azerbaijani', code: 'az', flag: '🇦🇿' },
  { name: 'Bambara', code: 'bm', flag: '🇲🇱' },
  { name: 'Bashkir', code: 'ba', flag: '🇷🇺' },
  { name: 'Basque', code: 'eu', flag: '🇪🇸' },
  { name: 'Belarusian', code: 'be', flag: '🇧🇾' },
  { name: 'Bengali (Bangla)', code: 'bn', flag: '🇧🇩' },
  { name: 'Bihari', code: 'bh', flag: '🇮🇳' },
  { name: 'Bislama', code: 'bi', flag: '🇻🇺' },
  { name: 'Bosnian', code: 'bs', flag: '🇧🇦' },
  { name: 'Breton', code: 'br', flag: '🇫🇷' },
  { name: 'Bulgarian', code: 'bg', flag: '🇧🇬' },
  { name: 'Burmese', code: 'my', flag: '🇲🇲' },
  { name: 'Catalan', code: 'ca', flag: '🇪🇸' },
  { name: 'Chamorro', code: 'ch', flag: '🇬🇺' },
  { name: 'Chechen', code: 'ce', flag: '🇷🇺' },
  { name: 'Chichewa, Chewa, Nyanja', code: 'ny', flag: '🇲🇼' },
  { name: 'Chinese', code: 'zh', flag: '🇨🇳' },
  { name: 'Chinese (Simplified)', code: 'zh-Hans', flag: '🇨🇳' },
  { name: 'Chinese (Traditional)', code: 'zh-Hant', flag: '🇨🇳' },
  { name: 'Chuvash', code: 'cv', flag: '🇷🇺' },
  { name: 'Cornish', code: 'kw', flag: '🇬🇧' },
  { name: 'Corsican', code: 'co', flag: '🇫🇷' },
  { name: 'Cree', code: 'cr', flag: '🇨🇦' },
  { name: 'Croatian', code: 'hr', flag: '🇭🇷' },
  { name: 'Czech', code: 'cs', flag: '🇨🇿' },
  { name: 'Danish', code: 'da', flag: '🇩🇰' },
  { name: 'Divehi, Dhivehi, Maldivian', code: 'dv', flag: '🇲🇻' },
  { name: 'Dutch', code: 'nl', flag: '🇳🇱' },
  { name: 'Dzongkha', code: 'dz', flag: '🇧🇹' },
  { name: 'English', code: 'en', flag: '🇬🇧' },
  { name: 'Esperanto', code: 'eo', flag: '🌍' },
  { name: 'Estonian', code: 'et', flag: '🇪🇪' },
  { name: 'Ewe', code: 'ee', flag: '🇬🇭' },
  { name: 'Faroese', code: 'fo', flag: '🇫🇴' },
  { name: 'Fijian', code: 'fj', flag: '🇫🇯' },
  { name: 'Finnish', code: 'fi', flag: '🇫🇮' },
  { name: 'French', code: 'fr', flag: '🇫🇷' },
  { name: 'Fula, Fulah, Pulaar, Pular', code: 'ff', flag: '🇸🇳' },
  { name: 'Galician', code: 'gl', flag: '🇪🇸' },
  { name: 'Gaelic (Scottish)', code: 'gd', flag: '🏴' },
  { name: 'Gaelic (Manx)', code: 'gv', flag: '🇮🇲' },
  { name: 'Georgian', code: 'ka', flag: '🇬🇪' },
  { name: 'German', code: 'de', flag: '🇩🇪' },
  { name: 'Greek', code: 'el', flag: '🇬🇷' },
  { name: 'Greenlandic', code: 'kl', flag: '🇬🇱' },
  { name: 'Guarani', code: 'gn', flag: '🇵🇾' },
  { name: 'Gujarati', code: 'gu', flag: '🇮🇳' },
  { name: 'Haitian Creole', code: 'ht', flag: '🇭🇹' },
  { name: 'Hausa', code: 'ha', flag: '🇳🇬' },
  { name: 'Hebrew', code: 'he', flag: '🇮🇱' },
  { name: 'Herero', code: 'hz', flag: '🇳🇦' },
  { name: 'Hindi', code: 'hi', flag: '🇮🇳' },
  { name: 'Hiri Motu', code: 'ho', flag: '🇵🇬' },
  { name: 'Hungarian', code: 'hu', flag: '🇭🇺' },
  { name: 'Icelandic', code: 'is', flag: '🇮🇸' },
  { name: 'Ido', code: 'io', flag: '🌍' },
  { name: 'Igbo', code: 'ig', flag: '🇳🇬' },
  { name: 'Indonesian', code: 'id, in', flag: '🇮🇩' },
  { name: 'Interlingua', code: 'ia', flag: '🌍' },
  { name: 'Interlingue', code: 'ie', flag: '🌍' },
  { name: 'Inuktitut', code: 'iu', flag: '🇨🇦' },
  { name: 'Inupiak', code: 'ik', flag: '🇺🇸' },
  { name: 'Irish', code: 'ga', flag: '🇮🇪' },
  { name: 'Italian', code: 'it', flag: '🇮🇹' },
  { name: 'Japanese', code: 'ja', flag: '🇯🇵' },
  { name: 'Javanese', code: 'jv', flag: '🇮🇩' },
  { name: 'Kalaallisut, Greenlandic', code: 'kl', flag: '🇬🇱' },
  { name: 'Kannada', code: 'kn', flag: '🇮🇳' },
  { name: 'Kanuri', code: 'kr', flag: '🇳🇬' },
  { name: 'Kashmiri', code: 'ks', flag: '🇮🇳' },
  { name: 'Kazakh', code: 'kk', flag: '🇰🇿' },
  { name: 'Khmer', code: 'km', flag: '🇰🇭' },
  { name: 'Kikuyu', code: 'ki', flag: '🇰🇪' },
  { name: 'Kinyarwanda (Rwanda)', code: 'rw', flag: '🇷🇼' },
  { name: 'Kirundi', code: 'rn', flag: '🇧🇮' },
  { name: 'Kyrgyz', code: 'ky', flag: '🇰🇬' },
  { name: 'Komi', code: 'kv', flag: '🇷🇺' },
  { name: 'Kongo', code: 'kg', flag: '🇨🇩' },
  { name: 'Korean', code: 'ko', flag: '🇰🇷' },
  { name: 'Kurdish', code: 'ku', flag: '🏴' },
  { name: 'Kwanyama', code: 'kj', flag: '🇦🇴' },
  { name: 'Lao', code: 'lo', flag: '🇱🇦' },
  { name: 'Latin', code: 'la', flag: '🏴' },
  { name: 'Latvian (Lettish)', code: 'lv', flag: '🇱🇻' },
  { name: 'Limburgish ( Limburger)', code: 'li', flag: '🇳🇱' },
  { name: 'Lingala', code: 'ln', flag: '🇨🇩' },
  { name: 'Lithuanian', code: 'lt', flag: '🇱🇹' },
  { name: 'Luga-Katanga', code: 'lu', flag: '🇨🇩' },
  { name: 'Luganda, Ganda', code: 'lg', flag: '🇺🇬' },
  { name: 'Luxembourgish', code: 'lb', flag: '🇱🇺' },
  { name: 'Manx', code: 'gv', flag: '🇮🇲' },
  { name: 'Macedonian', code: 'mk', flag: '🇲🇰' },
  { name: 'Malagasy', code: 'mg', flag: '🇲🇬' },
  { name: 'Malay', code: 'ms', flag: '🇲🇾' },
  { name: 'Malayalam', code: 'ml', flag: '🇮🇳' },
  { name: 'Maltese', code: 'mt', flag: '🇲🇹' },
  { name: 'Maori', code: 'mi', flag: '🇳🇿' },
  { name: 'Marathi', code: 'mr', flag: '🇮🇳' },
  { name: 'Marshallese', code: 'mh', flag: '🇲🇭' },
  { name: 'Moldavian', code: 'mo', flag: '🇲🇩' },
  { name: 'Mongolian', code: 'mn', flag: '🇲🇳' },
  { name: 'Nauru', code: 'na', flag: '🇳🇷' },
  { name: 'Navajo', code: 'nv', flag: '🇺🇸' },
  { name: 'Ndonga', code: 'ng', flag: '🇳🇦' },
  { name: 'Northern Ndebele', code: 'nd', flag: '🇿🇦' },
  { name: 'Nepali', code: 'ne', flag: '🇳🇵' },
  { name: 'Norwegian', code: 'no', flag: '🇳🇴' },
  { name: 'Norwegian bokmål', code: 'nb', flag: '🇳🇴' },
  { name: 'Norwegian nynorsk', code: 'nn', flag: '🇳🇴' },
  { name: 'Nuosu', code: 'ii', flag: '🇨🇳' },
  { name: 'Occitan', code: 'oc', flag: '🇫🇷' },
  { name: 'Ojibwe', code: 'oj', flag: '🇨🇦' },
  { name: 'Old Church Slavonic, Old Bulgarian', code: 'cu', flag: '🏴' },
  { name: 'Oriya', code: 'or', flag: '🇮🇳' },
  { name: 'Oromo (Afaan Oromo)', code: 'om', flag: '🇪🇹' },
  { name: 'Ossetian', code: 'os', flag: '🇬🇪' },
  { name: 'Pāli', code: 'pi', flag: '🇱🇰' },
  { name: 'Pashto, Pushto', code: 'ps', flag: '🇦🇫' },
  { name: 'Persian (Farsi)', code: 'fa', flag: '🇮🇷' },
  { name: 'Polish', code: 'pl', flag: '🇵🇱' },
  { name: 'Portuguese', code: 'pt', flag: '🇵🇹' },
  { name: 'Punjabi (Eastern)', code: 'pa', flag: '🇮🇳' },
  { name: 'Quechua', code: 'qu', flag: '🇵🇪' },
  { name: 'Romansh', code: 'rm', flag: '🇨🇭' },
  { name: 'Romanian', code: 'ro', flag: '🇷🇴' },
  { name: 'Russian', code: 'ru', flag: '🇷🇺' },
  { name: 'Sami', code: 'se', flag: '🇸🇪' },
  { name: 'Samoan', code: 'sm', flag: '🇼🇸' },
  { name: 'Sango', code: 'sg', flag: '🇨🇫' },
  { name: 'Sanskrit', code: 'sa', flag: '🏴' },
  { name: 'Serbian', code: 'sr', flag: '🇷🇸' },
  { name: 'Serbo-Croatian', code: 'sh', flag: '🇷🇸' },
  { name: 'Sesotho', code: 'st', flag: '🇱🇸' },
  { name: 'Setswana', code: 'tn', flag: '🇧🇼' },
  { name: 'Shona', code: 'sn', flag: '🇿🇼' },
  { name: 'Sichuan Yi', code: 'ii', flag: '🇨🇳' },
  { name: 'Sindhi', code: 'sd', flag: '🇵🇰' },
  { name: 'Sinhalese', code: 'si', flag: '🇱🇰' },
  { name: 'Siswati', code: 'ss', flag: '🇸🇿' },
  { name: 'Slovak', code: 'sk', flag: '🇸🇰' },
  { name: 'Slovenian', code: 'sl', flag: '🇸🇮' },
  { name: 'Somali', code: 'so', flag: '🇸🇴' },
  { name: 'Southern Ndebele', code: 'nr', flag: '🇿🇦' },
  { name: 'Spanish', code: 'es', flag: '🇪🇸' },
  { name: 'Sundanese', code: 'su', flag: '🇮🇩' },
  { name: 'Swahili (Kiswahili)', code: 'sw', flag: '🇹🇿' },
  { name: 'Swati', code: 'ss', flag: '🇸🇿' },
  { name: 'Swedish', code: 'sv', flag: '🇸🇪' },
  { name: 'Tagalog', code: 'tl', flag: '🇵🇭' },
  { name: 'Tahitian', code: 'ty', flag: '🇵🇫' },
  { name: 'Tajik', code: 'tg', flag: '🇹🇯' },
  { name: 'Tamil', code: 'ta', flag: '🇮🇳' },
  { name: 'Tatar', code: 'tt', flag: '🇷🇺' },
  { name: 'Telugu', code: 'te', flag: '🇮🇳' },
  { name: 'Thai', code: 'th', flag: '🇹🇭' },
  { name: 'Tibetan', code: 'bo', flag: '🇨🇳' },
  { name: 'Tigrinya', code: 'ti', flag: '🇪🇷' },
  { name: 'Tonga', code: 'to', flag: '🇹🇴' },
  { name: 'Tsonga', code: 'ts', flag: '🇿🇦' },
  { name: 'Turkish', code: 'tr', flag: '🇹🇷' },
  { name: 'Turkmen', code: 'tk', flag: '🇹🇲' },
  { name: 'Twi', code: 'tw', flag: '🇬🇭' },
  { name: 'Uyghur', code: 'ug', flag: '🇨🇳' },
  { name: 'Ukrainian', code: 'uk', flag: '🇺🇦' },
  { name: 'Urdu', code: 'ur', flag: '🇵🇰' },
  { name: 'Uzbek', code: 'uz', flag: '🇺🇿' },
  { name: 'Venda', code: 've', flag: '🇿🇦' },
  { name: 'Vietnamese', code: 'vi', flag: '🇻🇳' },
  { name: 'Volapük', code: 'vo', flag: '🌍' },
  { name: 'Wallon', code: 'wa', flag: '🇧🇪' },
  { name: 'Welsh', code: 'cy', flag: '🏴' },
  { name: 'Wolof', code: 'wo', flag: '🇸🇳' },
  { name: 'Western Frisian', code: 'fy', flag: '🇳🇱' },
  { name: 'Xhosa', code: 'xh', flag: '🇿🇦' },
  { name: 'Yiddish', code: 'yi, ji', flag: '🇮🇱' },
  { name: 'Yoruba', code: 'yo', flag: '🇳🇬' },
  { name: 'Zhuang, Chuang', code: 'za', flag: '🇨🇳' },
  { name: 'Zulu', code: 'zu', flag: '🇿🇦' },
]
