import { callApi } from './config/callApi'

export interface IRegisterParams {
  username: string
  email: string
  password: string
}

export const register = async (body: IRegisterParams): Promise<any> => {
  return callApi({
    method: 'post',
    url: `/auth/register`,
    data: body,
  })
}

export interface ILoginParams {
  email: string
  password: string
}

export const login = async (body: ILoginParams): Promise<any> => {
  return callApi({
    method: 'post',
    url: `/auth/login`,
    data: body,
  })
}

export interface IRefreshParams {
  refresh: string
}

export const refresh = async (body: IRefreshParams): Promise<any> => {
  return callApi({
    method: 'post',
    url: `/auth/refresh`,
    data: body,
  })
}

export const getMe = async (): Promise<any> => {
  return callApi({
    method: 'get',
    url: `/auth/me`,
  })
}
