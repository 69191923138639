import { Avatar, Box, Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import Grid from '@mui/material/Grid'
import { GoogleIcon } from '../../components/Icons/GoogleIcon'
import { FacebookIcon } from '../../components/Icons/FacebookIcon'
import { InstagramIcon } from '../../components/Icons/InstagramIcon'
import { AppStoreIcon } from '../../components/Icons/AppStoreIcon'

const IconCard = ({ children }: any) => {
  return (
    <Card
      variant='outlined'
      style={{
        width: 50,
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </Card>
  )
}

interface Props {}

export const UserMain: React.FC<Props> = () => {
  return (
    <Box my={4}>
      <Card>
        <CardContent>
          <Box display='flex' justifyContent='space-between'>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={4} md={3}>
                <Avatar
                  alt='Remy Sharp'
                  src='https://s3-alpha-sig.figma.com/img/bdc6/db2a/3a906b3de8eaa53e14582edf5c918b5d?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=XRvZQL-92C9SsEbVh0zO7sSIrADRaqPPkd5LCm9UC9-U06xlbk8VYOgnIkeRPOGdddpxE4FoeMHtExv2mHjbiRZcp75BeUwRcsk9sKfrP6D1pE1-aErkhExTHWW9TSCtQbE~O2lx03RgsZqQkvarLsiHyuW7FrvhDGjT~JNH-9EZyUqeUGa4jgKYanb2Nj81GjNLscMA7GcI2ZTlGfTfl9eEKymSJnyna6LTnE1wGdxMToTI70p~RF3xBLzOG9TIHHysHXo0aDRPJhLq3aRK0mYNkMA~YeVBpEc14L2KRo4vMbTLt88orxUeH4kQLIncE88AMEKEvg-DLreSC9KD~g'
                  sx={{ width: '100%', height: 'auto' }}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={7}>
                <Grid container spacing={4} justifyContent='space-between' alignItems='center'>
                  <Grid item>
                    <Typography variant='caption' color='#2196F3' mb={1}>
                      Player
                    </Typography>
                    <Typography variant='h5' mb={4}>
                      Lillian Jameson
                    </Typography>

                    <Box display='flex' alignItems='center' gap={1} mb={2}>
                      <Typography variant='subtitle1'>E-mail:</Typography>
                      <Typography variant='body1'>Sabrina_Ziemann@yahoo.com</Typography>
                    </Box>
                    <Box display='flex' alignItems='center' gap={1} mb={2}>
                      <Typography variant='subtitle1'>Register date:</Typography>
                      <Typography variant='body1'>04/04/28</Typography>
                    </Box>
                    <Box display='flex' alignItems='center' gap={1} mb={6}>
                      <Typography variant='subtitle1'>Time in HUB:</Typography>
                      <Typography variant='body1'>316 min</Typography>
                    </Box>

                    <Box display='flex' gap={2}>
                      <IconCard>
                        <GoogleIcon />
                      </IconCard>
                      <IconCard>
                        <FacebookIcon />
                      </IconCard>
                      <IconCard>
                        <InstagramIcon />
                      </IconCard>
                      <IconCard>
                        <AppStoreIcon />
                      </IconCard>
                    </Box>
                  </Grid>
                  <Grid item gap={4}>
                    <Box display='flex' alignItems='center' gap={1} mb={2}>
                      <Typography variant='subtitle1'>Events end:</Typography>
                      <Typography variant='body1'>Sabrina_Ziemann@yahoo.com</Typography>
                    </Box>
                    <Box display='flex' alignItems='center' gap={1} mb={2}>
                      <Typography variant='subtitle1'>Time on stream:</Typography>
                      <Typography variant='body1'>450 min</Typography>
                    </Box>
                    <Box display='flex' alignItems='center' gap={1} mb={2}>
                      <Typography variant='subtitle1'>Coins:</Typography>
                      <Typography variant='body1'>810</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
