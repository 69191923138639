import React from 'react'
import { Box, Button, Container, CssBaseline, Grid, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../constants/routes'
import { register } from '../api/auth'
import { TOKEN } from '../constants/general'
import { useAppDispatch } from '../hooks/useReduxHooks'
import { setAuth } from '../store/auth/auth.slice'

export const Register = () => {
  const dispatch = useAppDispatch()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [loading, setLoading] = useState(false)

  const handleRegister = async () => {
    setLoading(true)
    register({ password: password, email: email, username: name })
      .then(({ token, refresh, user }) => {
        localStorage.setItem(TOKEN.access, token)
        localStorage.setItem(TOKEN.refresh, refresh)
        dispatch(setAuth({ user: user }))
      })
      .catch(() => setLoading(false))
  }

  return (
    <>
      <Container maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography variant='h5'>Register</Typography>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name='name'
                  required
                  fullWidth
                  id='name'
                  label='Name'
                  autoFocus
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id='email'
                  label='Email Address'
                  name='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name='password'
                  label='Password'
                  type='password'
                  id='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
            </Grid>
            <Button disabled={loading} fullWidth variant='contained' sx={{ mt: 3, mb: 2 }} onClick={handleRegister}>
              Register
            </Button>
            <Grid container>
              <Grid item>
                <Link to={ROUTES.login}>Already have an account? Login</Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  )
}
