import React from 'react'

export const getFormProps = (e: any): any => {
  return Object.entries(e.target).reduce((prev, [, v]: any) => {
    if (['text', 'textarea', 'number', 'select-one', 'email'].includes(v.type))
      return {
        ...prev,
        [v.name]: v.value,
      }
    else if (['checkbox', 'radio'].includes(v.type)) return { ...prev, [v.name]: v.checked }
    else if (['file'].includes(v.type)) return { ...prev, [v.name]: v.files?.[0] }
    return prev
  }, {})
}

export const setFormData = (elem: React.MutableRefObject<HTMLFormElement | null>, data: any) => {
  if (elem.current)
    Object.entries(elem.current).forEach(([, v]: any) => {
      if (['checkbox', 'radio'].includes(v.type) && v.name in data) v.checked = data[v.name]
      else if (v.name in data) v.value = data[v.name]
    })
}

export const getCookie = (cname: string) => {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) return c.substring(name.length, c.length)
  }
  return undefined
}

export const setCookie = (cname: string, cvalue: any, exdays: number) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}
