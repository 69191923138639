import React from 'react'

export const InstagramIcon = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_250_13011)'>
        <path
          d='M20.625 0H9.375C4.19813 0 0 4.19813 0 9.375V20.625C0 25.8019 4.19813 30 9.375 30H20.625C25.8019 30 30 25.8019 30 20.625V9.375C30 4.19813 25.8019 0 20.625 0ZM27.1875 20.625C27.1875 24.2437 24.2437 27.1875 20.625 27.1875H9.375C5.75625 27.1875 2.8125 24.2437 2.8125 20.625V9.375C2.8125 5.75625 5.75625 2.8125 9.375 2.8125H20.625C24.2437 2.8125 27.1875 5.75625 27.1875 9.375V20.625Z'
          fill='url(#paint0_linear_250_13011)'
        />
        <path
          d='M15 7.5C10.8581 7.5 7.5 10.8581 7.5 15C7.5 19.1419 10.8581 22.5 15 22.5C19.1419 22.5 22.5 19.1419 22.5 15C22.5 10.8581 19.1419 7.5 15 7.5ZM15 19.6875C12.4162 19.6875 10.3125 17.5837 10.3125 15C10.3125 12.4144 12.4162 10.3125 15 10.3125C17.5837 10.3125 19.6875 12.4144 19.6875 15C19.6875 17.5837 17.5837 19.6875 15 19.6875Z'
          fill='url(#paint1_linear_250_13011)'
        />
        <path
          d='M23.0619 7.93698C23.6138 7.93698 24.0612 7.48955 24.0612 6.93761C24.0612 6.38567 23.6138 5.93823 23.0619 5.93823C22.5099 5.93823 22.0625 6.38567 22.0625 6.93761C22.0625 7.48955 22.5099 7.93698 23.0619 7.93698Z'
          fill='url(#paint2_linear_250_13011)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_250_13011'
          x1='2.74622'
          y1='27.254'
          x2='27.2538'
          y2='2.74603'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC107' />
          <stop offset='0.507' stopColor='#F44336' />
          <stop offset='0.99' stopColor='#9C27B0' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_250_13011'
          x1='9.69684'
          y1='20.3032'
          x2='20.3032'
          y2='9.69684'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC107' />
          <stop offset='0.507' stopColor='#F44336' />
          <stop offset='0.99' stopColor='#9C27B0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_250_13011'
          x1='22.3553'
          y1='7.64439'
          x2='23.7685'
          y2='6.23101'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC107' />
          <stop offset='0.507' stopColor='#F44336' />
          <stop offset='0.99' stopColor='#9C27B0' />
        </linearGradient>
        <clipPath id='clip0_250_13011'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
